import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, SvgIcon, Typography, useMediaQuery } from '@mui/material'
import Row from '../../components/Row'
import Column from '../../components/Column'

import Logo from '../../svg/logo/IconBoxed'
import TextLogo from '../../svg/TextLogo'
import AppleIcon from '@mui/icons-material/Apple'
import CreateButton from 'components/Navigation/CreateButton'
import { mdiGooglePlay, mdiInstagram, mdiLinkedin, mdiWhatsapp } from '@mdi/js'
import { useIsLoggedIn } from 'utils/hooks'
import Link from 'next/link'
import { useSelector } from 'react-redux'
import CreateButtonUI from '../Navigation/CreateButtonUI'

const GeneralFooter = ({
  maxWidth,
  hideBorder = false,
  color = null,
  isIndex = false,
}) => {
  const { t } = useTranslation('common')

  const desktopMode = useMediaQuery('(min-width:900px)')
  // @ts-expect-error Conditional hook is fine as isIndex never changes
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isMobileApp = isIndex
    ? false
    : useSelector((state) => state.user.isMobileApp)

  // @ts-expect-error Conditional hook is fine as isIndex never changes
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isLoggedIn = isIndex ? false : useIsLoggedIn()
  const androidClicked = () => {
    window.open(
      'https://play.google.com/store/apps/details?id=com.vlinderstorm.bash',
    )
  }
  const appleClicked = () => {
    window.open('https://apps.apple.com/app/bash-sociale-events/id1448137786')
  }

  const copyRight = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: !desktopMode ? 'column' : 'row-reverse',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          mt: 1.5,
        }}
      >
        <Typography variant='caption' sx={{ textAlign: 'center' }}>
          © BASH copyright and trademark 2025
        </Typography>
        <Row sx={{ alignItems: 'center', gap: 1.5, fill: 'text.primary' }}>
          <AppleIcon
            sx={{ width: 14, height: 14, fill: 'grey.600', cursor: 'pointer' }}
            onClick={appleClicked}
          />
          <Box
            sx={{
              height: 14,
              width: '1px',
              borderRadius: '100px',
              bgcolor: 'grey.600',
            }}
          />
          <SvgIcon
            sx={{
              width: 14,
              height: 14,
              cursor: 'pointer',
            }}
            onClick={androidClicked}
          >
            <path d={mdiGooglePlay} />
          </SvgIcon>
        </Row>
      </Box>
    )
  }

  const showSupport = () => {
    window.open('https://wa.me/message/4GG2EGM4GIDBD1')
  }

  if (isMobileApp) return null

  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        color: color ?? 'grey.700',
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: !desktopMode ? 'column' : 'row',
          alignItems: !desktopMode ? 'center' : 'center',
          justifyContent: 'space-between',
          pt: 5,
          pb: 6,
          maxWidth: theme.breakpoints.values.containerMaxWidth,
          width: '100%',
          borderTop: hideBorder ? 'none' : `1px solid ${theme.palette.divider}`,
          mr: hideBorder ? 0 : !desktopMode ? 2 : 4,
          ml: hideBorder ? 0 : !desktopMode ? 2 : 4,
          gap: !desktopMode ? 2.5 : undefined,
        })}
        style={{ maxWidth }}
      >
        <Column
          sx={{
            gap: 1.5,
            alignItems: !desktopMode ? 'center' : 'flex-start',
          }}
        >
          <a href='/' aria-label='bash'>
            <Row sx={{ alignItems: 'center', gap: 1 }}>
              <Logo
                style={{
                  width: 14.44,
                  height: 15,
                  // color: themeHook.palette.grey[700],
                }}
              />
              <TextLogo
                style={{
                  height: 13.52,
                  // color: themeHook.palette.grey[700]
                }}
              />
            </Row>
          </a>

          <Typography
            variant='caption'
            sx={
              {
                // color: 'grey.700'
              }
            }
          >
            Start the hype
          </Typography>
          {desktopMode && copyRight()}
        </Column>

        {!desktopMode &&
          (isIndex ? <CreateButtonUI fancy /> : <CreateButton fancy />)}

        <Column
          sx={{
            alignItems: !desktopMode ? 'center' : 'flex-end',
            gap: 1.5,
          }}
        >
          <Row sx={{ alignItems: 'center', gap: 1.5 }}>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.instagram.com/bash.social/'
              aria-label='Instagram'
            >
              <SvgIcon
                sx={{
                  width: 18,
                  height: 18,
                  // color: 'grey.700'
                }}
              >
                <path d={mdiInstagram} />
              </SvgIcon>
            </a>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.tiktok.com/@bash.social'
              aria-label='Tiktok'
            >
              <SvgIcon
                sx={{
                  width: 18,
                  height: 18,
                  // color: 'grey.700'
                }}
              >
                <path
                  d={
                    'M12.525.02c1.31-.02 2.61-.01 3.91-.02.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z'
                  }
                />
              </SvgIcon>
            </a>

            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://linkedin.com/company/bashsocial'
              aria-label='Linkedin'
            >
              <SvgIcon
                sx={{
                  width: 18,
                  height: 18,
                  // color: 'grey.700'
                }}
              >
                <path d={mdiLinkedin} />
              </SvgIcon>
            </a>

            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://wa.me/message/4GG2EGM4GIDBD1'
              aria-label='Whatsapp'
            >
              <SvgIcon
                sx={{
                  width: 18,
                  height: 18,
                  // color: 'grey.700'
                }}
              >
                <path d={mdiWhatsapp} />
              </SvgIcon>
            </a>
          </Row>
          <Row
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2,
              // color: 'grey.700',
              flexWrap: 'wrap',
              '& a': {
                textDecoration: 'underline',
              },
            }}
          >
            {desktopMode &&
              (isIndex ? (
                <CreateButtonUI href={'/create'} fancy />
              ) : (
                <CreateButton fancy />
              ))}
            {isLoggedIn && (
              <Typography variant='caption'>
                <Link aria-label='My events' href='/home'>
                  My events
                </Link>
              </Typography>
            )}
            {isLoggedIn && (
              <Typography variant='caption'>
                <Link aria-label='For you' href='/foryou'>
                  For you
                </Link>
              </Typography>
            )}
            {!isLoggedIn && (
              <Typography variant='caption'>
                <Link aria-label='Hot events' href='/foryou'>
                  Hot events
                </Link>
              </Typography>
            )}
            {!isLoggedIn && (
              <Typography variant='caption'>
                <Link aria-label='Sign in' href='/signIn'>
                  {t('signIn')}
                </Link>
              </Typography>
            )}
          </Row>
          <Row
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2,
              // color: 'text.tertiary',
              flexWrap: 'wrap',
              '& a': {
                textDecoration: 'underline',
              },
            }}
          >
            {/* <Typography variant='caption'>
              <Link href='/about'>{t('aboutBash')}</Link>
            </Typography> */}
            <Typography variant='caption'>
              <Link aria-label='Jobs' href='/jobs'>
                Jobs
              </Link>
            </Typography>
            {/*<Typography variant='caption'>*/}
            {/*  <Link href='/pricing'>Pricing</Link>*/}
            {/*</Typography>*/}
            <Typography variant='caption'>
              <Link aria-label='Privacy' href='/privacy'>
                Privacy
              </Link>
            </Typography>
            <Typography variant='caption'>
              <Link aria-label='Terms' href='/terms'>
                Terms & conditions
              </Link>
            </Typography>
            <Typography variant='caption'>
              <Box
                component='div'
                sx={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                aria-label='Support'
                onClick={() => showSupport()}
              >
                Support
              </Box>
            </Typography>
          </Row>

          {!desktopMode && copyRight()}
        </Column>
      </Box>
    </Box>
  )
}
export default GeneralFooter
